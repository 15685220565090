@use "../styles/variables" as vars;

.layout-container {
  padding-left: 0px;
  padding-right: 0px;
  &_start {
    color: vars.$tbc-color-new-blue;
    a:link, a:visited, a:active, a:hover {
      color: vars.$tbc-color-new-blue;
      text-decoration: none;
      font-weight: 800;
    }
  }
  &_content {
    margin-top: 80px;
    color: rgba(0, 0, 0, 0.55);
  }
}