@use "../../styles/variables" as vars;

.footer {  
  background-color: #ffffff;
  background-image: url(../../assets/Footer_bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 110px;
  &-content {
    padding-bottom: 34px;
    border-bottom: 1px solid rgba(240, 240, 240, 0.39);
  }
  &-links {
    padding-top: 1.25rem;
    & a {
      color: #fff;
      &.active-route {
        color: #fff;
      }
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
  &-copy {
    padding-top: 1.25rem;
  }
  color: #fff;
}