@use "../../styles/variables" as vars;

.btn-base {
  padding: 0.375rem 3rem;
  border-radius: vars.$border-radius;
  font-size: 1.25rem;
  font-weight: 500;
  color: vars.$tbc-color-white;
}

.btn-primary {
  background-color: vars.$tbc-color-new-blue;
  border-color: vars.$tbc-color-new-blue;
  &:hover {
    background-color: vars.$tbc-color-purple-15pclight;
    border-color: vars.$tbc-color-purple-15pclight;
  }
}

.btn-secondary {
  color: vars.$tbc-color-new-blue;
  background-color: vars.$tbc-color-white;
  border-color: vars.$tbc-color-new-blue;
}

.btn-delete {
  text-align: center;
  padding: 0.375rem 1rem;
}

.btn_icon {
  margin-right: 0.75rem;
}