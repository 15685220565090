@use "../../styles/variables" as vars;

.header {
  padding: 0 0.5rem;
  background-color: vars.$tbc-color-white;
  color: vars.$tbc-color-new-blue;
  margin-bottom: 1rem;
  height: 32px;
  &_brand {
    width: 250px;
    height: auto;
  }
}
