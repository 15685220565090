@use "../../styles/variables" as vars;

.landing-header {
  padding: 0 0.5rem;
  background-color: vars.$tbc-color-white;
  color: vars.$tbc-color-new-blue;
  margin-bottom: 1rem;
  height: 32px;
  &_content {
    padding-top: 60px;
    padding-bottom: 40px;
    color: rgba(0, 0, 0, 0.55);
    a, a:active, a:visited {
      color: vars.$tbc-color-new-blue;
      text-decoration: none;
    }
    h1 {
      color: vars.$tbc-color-new-blue;
      font-size: 40px;
      line-height: 1.22;
      padding-bottom: 20px;
    }
  }
  &_link {
    a, a:active, a:visited {
      width: 32px;
      color: vars.$tbc-color-new-blue;
      text-decoration: none;
      font-weight: 400;
    }
  }
  &_title {
    margin: 0 auto;
    h1 {
      color: vars.$tbc-color-new-blue;
      font-size: 20px;
      margin: 0;
      padding: 6px 0;
      line-height: 1;
      font-weight: 700;
    }
  }
  &_app-download-title {
    text-transform: uppercase;
  }
}
